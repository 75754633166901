(function ($) {
  Drupal.behaviors.ieUpgradePopupV1 = {
    attach: function (context) {
      var $ieUpgradeContainer = $('.js-sitewide-ie-upgrade', context);
      var $ieUpgradeClose = $('.js-sitewide-ie-upgrade-close', context);
      /* Colorbox does not work on less than or equal IE9
       * Display IE Upgrade Popup using basic function: append to the body.
       */
      var ieNavigator = navigator.userAgent.match(/MSIE 10|MSIE 9/);

      if (ieNavigator) {
        $ieUpgradeContainer.removeClass('hidden');
        $ieUpgradeClose.on('click', function () {
          $ieUpgradeContainer.addClass('hidden');
        });
      }
    }
  };
})(jQuery);
